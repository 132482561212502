import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import './MyDashboard.css';

  const MyDashboard = ({account}) => {
  const [contracts, setContracts] = useState([]);
  const [disputes, setDisputes] = useState([]);  // Define disputes and setDisputes here
  const SERVER_IP = process.env.REACT_APP_SERVER_IP;


  useEffect(() => {
    const fetchContracts = async () => {
      try {
        const response = await fetch(`${SERVER_IP}/api/contracts?creator_eth_address=${account}`);
        if (!response.ok) {
          throw new Error('Failed to fetch contracts');
        }

        const contractsData = await response.json();
        setContracts(contractsData);
      } catch (error) {
        console.error('Failed to fetch contracts:', error);
      }
    };
    if(account) fetchContracts();
  }, [account]);

  useEffect(() => {
    const fetchDisputes = async () => {
      try {
        const response = await fetch(`${SERVER_IP}/api/disputes?eth_address=${account}`);
        if (!response.ok) {
          throw new Error('Failed to fetch disputes');
        }
  
        const disputesData = await response.json();
        console.log(disputesData); // Log the response data

        setDisputes(disputesData);
      } catch (error) {
        console.error('Failed to fetch disputes:', error);
      }
    };
    
    if(account) fetchDisputes();
  }, [account]);

  const getActionRequiredLink = (dispute, account) => {
    const plaintiffIsUser = dispute.plaintiff_eth_address === account;
    switch (dispute.status) {
        case 'AWAITING_REBUTTAL':
            return plaintiffIsUser ? 'Awaiting Plaintiff Rebuttal' : <Link to={`/disputes/${dispute.dispute_ref_id}/rebuttal`}>Submit Rebuttal</Link>;
        case 'ARBITRATION':
          if (plaintiffIsUser) {
            // If the user is the plaintiff and their own answers don't exist or are incomplete,
            // they should go to arbitration. If their answers are complete, but the defendant's aren't,
            // the plaintiff should be waiting for the defendant.
            return (!dispute.plaintiffQA?.answers || dispute.plaintiffQA.answers.some(answer => answer === null)) ?
              <Link to={`/arbitration/${dispute.dispute_ref_id}`}>Go to arbitration</Link> :
              (!dispute.defendantQA?.answers || dispute.defendantQA.answers.some(answer => answer === null)) ?
              'Waiting for defendant to complete arbitration.' : <Link to={`/arbitration/${dispute.dispute_ref_id}`}>Go to arbitration</Link>;
          } else {
            // If the user is the defendant and their own answers don't exist or are incomplete,
            // they should go to arbitration. If their answers are complete, but the plaintiff's aren't,
            // the defendant should be waiting for the plaintiff.
            return (!dispute.defendantQA?.answers || dispute.defendantQA.answers.some(answer => answer === null)) ?
              <Link to={`/arbitration/${dispute.dispute_ref_id}`}>Go to arbitration</Link> :
              (!dispute.plaintiffQA?.answers || dispute.plaintiffQA.answers.some(answer => answer === null)) ?
              'Waiting for plaintiff to complete arbitration.' : <Link to={`/arbitration/${dispute.dispute_ref_id}`}>Go to arbitration</Link>;
          }          
        case 'AWAITING_VERDICT':
            return 'Awaiting Verdict';
        case 'RESOLVED':
          return <Link to={`/verdict-display/${dispute.dispute_ref_id}`}>Verdict Rendered</Link>; // Modified this line to use the Link component
        default:
            return 'PENDING';
    }
    
};

const handleDeleteContract = async (contractId) => {
  const confirmed = window.confirm('Are you sure you want to delete this contract?');
  if (confirmed) {
    try {
      await fetch(`${SERVER_IP}/api/contracts/${contractId}`, {
        method: 'DELETE',
      });
      setContracts(contracts.filter(c => c.contract_id !== contractId));
    } catch (error) {
      console.error('Failed to delete contract:', error);
    }
  }
};

const handleDeleteDispute = async (disputeId) => {
  const confirmed = window.confirm('Are you sure you want to delete this dispute?');
  if (confirmed) {
    try {
      await fetch(`${SERVER_IP}/api/disputes/${disputeId}`, {
        method: 'DELETE',
      });
      setDisputes(disputes.filter(d => d.dispute_ref_id !== disputeId));
    } catch (error) {
      console.error('Failed to delete dispute:', error);
    }
  }
};


  return (
    <div className="dashboard">
      <h2>My Dashboard</h2>
      <div className="wallet-info">
        <strong>Wallet Address:</strong> {account}
      </div>
      <div className="table-wrapper">
        <section className="contracts-table">
          <h3>Contracts</h3>
          <table>
          <thead>
            <tr>
              <th style={{width: '15%'}}>Timestamp</th> 
              <th style={{width: '20%'}}>Contract Title</th>
              <th style={{width: '15%'}}>Contract ID</th>
              <th style={{width: '15%'}}>Contract Revision</th>
              <th style={{width: '20%'}}>Link</th>
              <th style={{width: '15%'}}>Delete</th>
            </tr>
          </thead>
          <tbody>
            {contracts.map((contract) => (
              <tr key={contract.contract_id}>
                <td>{contract.timestamp}</td>
                <td>{contract.contract_title}</td>
                <td>xxx{contract.contract_id.slice(-8)}</td>
                <td style={{width: '25%'}}>
                  {contract.revision.toFixed(2)}
                </td>
                <td>
                  <Link 
                    to={{
                      pathname: `/contracts/${contract.contract_id}`,
                      state: { contract }
                    }}
                  >
                    View Details
                  </Link>
                </td>
                <td>
                  <button onClick={() => handleDeleteContract(contract.contract_id)}
                  className="delete-button"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </section>
        <section className="disputes">
          <h3>Disputes</h3>
          <table>
            <thead>
              <tr>
                <th style={{width: '15%'}}>Timestamp</th>
                <th style={{width: '20%'}}>Dispute Title</th>
                <th style={{width: '15%'}}>Dispute ID</th>
                <th style={{width: '15%'}}>Contract Reference ID</th>
                <th style={{width: '10%'}}>Status</th>
                <th style={{width: '20%'}}>Action Required</th>
                <th style={{width: '15%'}}>Link</th>
                <th style={{width: '5%'}}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {disputes.map((dispute, index) => (
                <tr key={index} style={{color: dispute.plaintiff_eth_address === account ? 'red' : 'blue'}}>
                  <td>{dispute.timestamp}</td> {/* New timestamp column */}
                  <td>{dispute.dispute_title}</td>
                  <td>{dispute.dispute_ref_id}</td>
                  <td>{dispute.contract_ref_id}</td>
                  <td>{dispute.status}</td>
                  <td>
                    {getActionRequiredLink(dispute, account)}
                  </td>
                  <td>
                    <Link to={{pathname: `/disputes/${dispute.dispute_ref_id}`, state: { dispute }}} >
                      View Details
                    </Link>
                  </td>
                  <td>
                    <button onClick={() => handleDeleteDispute(dispute.dispute_ref_id)}
                    className="delete-button"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
        </div>
    </div>
  );
};

export default MyDashboard;
