import React, { useState, useEffect } from 'react';
import { Web3Provider } from "@ethersproject/providers";
import { getAddress } from '@ethersproject/address';

function Wallet({ onWalletConnect }) {
    const [account, setAccount] = useState('');

    useEffect(() => {
        const savedAccount = localStorage.getItem('account');
        if (savedAccount) {
            setAccount(savedAccount);
        }
    }, []);

    useEffect(() => {
        setupEthereumListeners();
    }, []);
    
    // This function checks if the browser is Ethereum-enabled and sets up the provider
    async function loadBlockchainData() {
        let provider;
        if (window.ethereum) {
	        provider = new Web3Provider(window.ethereum);

            try {
                // Request account access if needed
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                if (accounts.lenthg === 0) {
                    // Handle the case where the user hasn't connected any accounts
                    console.error("No Ethereum accounts are connected");
                    return;
                }
                const signer = provider.getSigner();
                const account = await signer.getAddress();
                // const account = '0xF10101010';
                setAccount(account);
                localStorage.setItem('account', account);
            } catch (error) {
                console.error("User denied account access");
            }
        }
        // Legacy dapp browsers
        else if (window.web3) {
        	provider = new Web3Provider(window.web3.currentProvider);
        }
        // If no injected web3 instance is detected, display an error
        else {
            alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
        }
    }

    // Declare loading state
    const [loading, setLoading] = useState(false);

    // Set loading state when connection starts/ends
    async function connectWallet() {
        try {
            setLoading(true);
            await loadBlockchainData();
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (account) {
            onWalletConnect(account);
        }
    }, [account, onWalletConnect]);

    const setupEthereumListeners = async () => {
        let provider;
        if (window.ethereum) {
            provider = new Web3Provider(window.ethereum);
            try {
                const signer = provider.getSigner();
                const account = await signer.getAddress();
                const formattedAccount = getAddress(account);
                setAccount(formattedAccount);
                localStorage.setItem('account', formattedAccount);
    
                window.ethereum.on('accountsChanged', async (accounts) => {
                    if (accounts.length > 0) {
                        const newAccount = accounts[0];
                        const formattedNewAccount = getAddress(newAccount);
                        setAccount(formattedNewAccount);
                        localStorage.setItem('account', formattedNewAccount);
                    }
                });
            } catch (error) {
                console.error("Error setting up Ethereum listeners:", error);
            }
        }
    };
    return (
        <button onClick={connectWallet} disabled={loading}>
            {account ? `Connected: ${account.substring(0, 6)}...${account.substring(38)}` : 'Need Contracts? Connect Etherium Wallet'}
        </button>
    );
}

export default Wallet;

