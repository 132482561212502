import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './DefendantRebuttal.css';

function DefendantRebuttal() {
  const [dispute, setDispute] = useState({});
  const [rebuttal, setRebuttal] = useState('');
  const { disputeId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // loading state to handle button disable
  const SERVER_IP = process.env.REACT_APP_SERVER_IP;


  useEffect(() => {
    fetch(`${SERVER_IP}/api/disputes/${disputeId}`)
      .then(response => response.json())
      .then(data => setDispute(data));
  }, [disputeId]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const updatedDispute = {
      ...dispute,
      defendant_rebuttal: rebuttal,
      status: 'ARBITRATION',
    };

    setLoading(true); // Set loading to true when submit starts

    try {
      const response = await fetch(`${SERVER_IP}/api/disputes/${dispute.dispute_ref_id}/rebuttal`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedDispute)
      });

      if (response.ok) {
        alert("Your rebuttal has been successfully submitted and the dispute status has been updated to 'ARBITRATION'");
        navigate('/my-dashboard'); // assumes '/dashboard' is the correct path for the MyDashboard component
      } else {
        alert('An error occurred. Please try again.');
      }
    } catch (err) {
      console.error('Error: ', err);
    } finally {
      setLoading(false); // Set loading to false when submit ends, regardless of the outcome
    }
  }

  return (
    <div className="defendant-rebuttal-form">
      <h2>Submit your rebuttal</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Dispute Title</label>
          <input type="text" value={dispute.dispute_title} readOnly />
        </div>
        <div>
          <label>Dispute ID</label>
          <input type="text" value={dispute.dispute_ref_id} readOnly />
        </div>
        <div>
          <label>Dispute Description</label>
          <textarea value={dispute.dispute_description} readOnly />
        </div>
        <div>
          <label>Your Rebuttal</label>
          <textarea 
            name="defendant_rebuttal" 
            value={rebuttal} 
            onChange={e => setRebuttal(e.target.value)} 
          />
        </div>
        <button 
          type="submit" 
          disabled={loading} 
          className={loading ? "loading-button" : ""}
        >
          {loading ? 'Deliberating...' : 'Submit'}
        </button>
      </form>
    </div>
  );
}

export default DefendantRebuttal;
