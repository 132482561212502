import React, { useState, useEffect, useCallback } from "react";
import { v4 as uuidv4 } from 'uuid'; // if you have not already imported it
import CryptoJS from 'crypto-js';
import './ContractEntry.css'; // import CSS


// Define initial form data
const initialFormData = () => ({
    contract_id: "",
    creator_eth_address: "", // Set default value to empty string
    contract_title: "",
    contract_description: "",
    revision: 1.0,
  });
  
  const ContractEntry = ({account}) => {
    const [formData, setFormData] = useState(initialFormData); // Initialize form data
  

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const SERVER_IP = process.env.REACT_APP_SERVER_IP;


    try {
        const response = await fetch(`${SERVER_IP}/api/contracts`, { // current backend API endpoint (NMT)

          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            contract_id: formData.contract_id,
            contract_title: formData.contract_title,
            creator_eth_address: formData.creator_eth_address,
            contract_description: formData.contract_description,
            revision: formData.revision,
          })
        });
    
        const data = await response.json();
  
        if (!response.ok) {
          throw new Error(data.message || "An error occurred while creating the dispute");
        }
  
        // If the server responds with success, include the dispute_ref_id in the alert message
        alert(`A contract has been successfully filed. Contract ID: ${data.contract_id}`);
        
    // Update formData with the new dispute_ref_id and a new contract ID
    setFormData(prevFormData => ({
        ...initialFormData(),
        creator_eth_address: prevFormData.creator_eth_address,
        contract_id: generateContractId(prevFormData.creator_eth_address)
      }));
  
      } catch (error) {
        console.log('Error:',error);
        alert("An error occurred while creating the dispute.");
      }
  };

  const autofillAddress = useCallback(async () => {
    if (account) { // Account was passed as a prop
        setFormData(prevFormData => ({
            ...prevFormData,
            creator_eth_address: account
        }));
    } else {
        alert('Please connect your Ethereum wallet.');
    }
},[account, setFormData]);


// Call autofillAddress on component mount to automatically fill in the creator_eth_address field
useEffect(() => {
    autofillAddress();
}, [account, autofillAddress]); // Update the field whenever the account changes


// Function for generating a new contract ID
const generateContractId = (creatorEthAddress) => {
    let randomNum = uuidv4(); // You may want to trim this down to 32 bits, depending on your requirements
    let hash = CryptoJS.SHA256(creatorEthAddress + randomNum);
    return hash + randomNum;
}
// Call autofillAddress on component mount to automatically fill in the creator_eth_address field
useEffect(() => {
    if(account) {
        setFormData(prevFormData => ({
            ...prevFormData,
            creator_eth_address: account,
            contract_id: generateContractId(account)
        }));
    }
}, [account]); // Update the field whenever the account changes

  return (
    <div className="contract-entry">
      <h1>Contract Entry</h1>
      <form onSubmit={handleSubmit}>
        <div className="contract-entry-field">
          <label>
            Contract ID:
            <input
              type="text"
              id="contract_id"
              name="contract_id"
              value={formData.contract_id}
              readOnly
            />
          </label>
        </div>
        <div className="contract-entry-field">
          <label>
            Creator Ethereum Address:
            <input
              type="text"
              name="creator_eth_address"
              value={formData.creator_eth_address}
              readOnly
            />
          </label>
        </div>
        <div className="contract-entry-field">
          <label>
            Contract Title:
            <input
              type="text"
              name="contract_title"
              value={formData.contract_title}
              onChange={handleInputChange}
            />
          </label>
        </div>
        <div className="contract-entry-field">
          <label>
            Contract Description:
            <textarea
              name="contract_description"
              value={formData.contract_description}
              onChange={handleInputChange}
            />
          </label>
        </div>
        <div className="contract-entry-field">
          <label>
            Revision:
            <input
              type="number"
              name="revision"
              value={formData.revision}
              readOnly
            />
          </label>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ContractEntry;

