import { useLocation, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './ContractDetail.css';

const ContractDetail = () => {
  const location = useLocation();
  const { contractId } = useParams();
  const [contract, setContract] = useState({});
  const SERVER_IP = process.env.REACT_APP_SERVER_IP;


  useEffect(() => {
    if(location.state){
      setContract(location.state.contract);
    }
    else {
      fetch(`${SERVER_IP}/api/contracts/${contractId}`)
      .then(response => {
        if (!response.ok) throw new Error('Failed to fetch contract');
        return response.json();
      })
      .then(data => setContract(data))
      .catch(error => console.error('Failed to fetch contract:', error));
    }
  }, [location.state, contractId]);

  return (
    <div className="contract-detail-container">
      <h1 className="contract-title">{contract.contract_title}</h1>
      <div className="detail-item">
        <div className="detail-title">Contract ID:</div>
        <div className="detail-value">{contract.contract_id}</div>
      </div>
      <div className="detail-item">
        <div className="detail-title">Contract Creator ETH Address:</div>
        <div className="detail-value">{contract.creator_eth_address}</div>
      </div>
      <div className="detail-item">
        <div className="detail-title">Contract Description:</div>
        <div className="detail-value">{contract.contract_description}</div>
      </div>
      <div className="detail-item">
        <div className="detail-title">Contract Revision:</div>
        <div className="detail-value">{Number(contract.revision).toFixed(2)}</div>
      </div>
    </div>
  );
};

export default ContractDetail;

