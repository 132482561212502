import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faXTwitter } from '@fortawesome/free-brands-svg-icons';


import './VerdictDisplay.css';
const SERVER_IP = process.env.REACT_APP_SERVER_IP;

// Assume your logo is in the public folder and is named verdikta-logo.png
const logoPath = '/background4.ico'; // or the URL if hosted on a CDN

const VerdictDisplay = () => {
  const [dispute, setDispute] = useState(null);
  const { dispute_ref_id } = useParams(); // Extracting from the route


  const shareUrl = window.location.href; // This will hold the URL of the current page for sharing


    useEffect(() => {
      if (dispute_ref_id) {
          fetch(`${SERVER_IP}/api/disputes/${dispute_ref_id}`)
              .then(response => response.json())
              .then(data => {
                  if (data.status === "RESOLVED") {
                      setDispute(data);
                  } else {
                      // Handle scenarios when the dispute status isn't "RESOLVED"
                      console.error("Dispute is not resolved.");
                  }
              })
              .catch(error => {
                  console.error('Error fetching dispute:', error);
              });
      }
  }, [dispute_ref_id]);

  if (!dispute) {
    return <div>Loading...</div>;
  }

  return (
    <div className="verdict-display">
      <div className="verdikta-logo-container">
        <Link to="/">
        <img src={logoPath} alt="Verdikta Logo" className="verdikta-logo"/>
        <div className="logo-overlay-text"><strong>Verdikta has rendered a verdict</strong></div>
        </Link>
      </div>
      <h1 className="dispute-title">Dispute Title: {dispute.dispute_title || "No title"}</h1>
      <p className="dispute-id">Dispute ID: {dispute.dispute_ref_id || "Instant Dispute"}</p>
      <p><strong>Description:</strong> {dispute.dispute_description || "No description"}</p>
      <p><strong>Defendant Rebuttal:</strong> {dispute.defendant_rebuttal || "No rebuttal"}</p>
      <h2>VERDICT</h2>
      <p className="verdict-text">{dispute.dispute_verdict || "No verdict"}</p>
      <div className="share-buttons">
        <CopyToClipboard text={shareUrl} onCopy={() => alert('Link copied to clipboard!')}>
          <button>Copy Link</button>
        </CopyToClipboard>
        <button
          onClick={() => window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent("Check out this verdict from Verdikta!")}`, "_blank")}
          className="share-button"
        >
          <FontAwesomeIcon icon={faTwitter} />  Share on Twitter
        </button>
      </div>
      <h3>Supporting Material:</h3>
      <h4>Plaintiff Questions and Answers:</h4>
      <ul>
        {Array.isArray(dispute.plaintiffQA?.questions) && Array.isArray(dispute.plaintiffQA?.answers) ? (
          dispute.plaintiffQA.questions.map((question, index) => (
            <li key={index}>
              <p><strong>Question:</strong> {question}</p>
              <p><strong>Answer:</strong> {dispute.plaintiffQA.answers[index]}</p>
            </li>
          ))
        ) : (
          <p>No questions and answers for the plaintiff.</p>
        )}
      </ul>
      <h4>Defendant Questions and Answers:</h4>
      <ul>
        {Array.isArray(dispute.defendantQA?.questions) && Array.isArray(dispute.defendantQA?.answers) ? (
          dispute.defendantQA.questions.map((question, index) => (
            <li key={index}>
              <p><strong>Question:</strong> {question}</p>
              <p><strong>Answer:</strong> {dispute.defendantQA.answers[index]}</p>
            </li>
          ))
        ) : (
          <p>No questions and answers for the defendant.</p>
        )}
      </ul>
    </div>
  );
};

export default VerdictDisplay;
