import React from 'react';
import './Disclaimer.css';

const Disclaimer = () => {
    return (
        <div className="disclaimer-container">
            <div className="content-rectangle">

    <h2>Disclaimer: Use of this Website</h2>
    <p>This website (the "Site") is provided by a collective of individuals for informational purposes only and is not intended to be a substitute for professional legal advice. The content on this Site includes information generated using computer algorithms and databases that are incomplete and under development. The content may not have been reviewed by any person and may include innaccuracies on people, places, facts, and logic.</p>

    <h2>No Attorney-Client Relationship</h2>
    <p>Accessing or using this Site does not create an attorney-client relationship between you and the website operator or any of its authors, contributors, or affiliates. You should not rely on the information provided on this Site as a substitute for obtaining legal advice from a qualified attorney.</p>

    <h2>Accuracy of Information</h2>
    <p>We make no guarantees or warranties about the accuracy, completeness, or reliability of the information provided. Legal principles, regulations, and other facts can change, and the information on this Site may not reflect the most current information or legal standards.</p>

    <h2>No Endorsement</h2>
    <p>Any links or references to third-party websites, products, or services on this Site are provided for convenience only. We do not endorse or make any representations about these third-party resources. You should use your own judgment and exercise caution when accessing external websites or relying on their content.</p>

    <h2>Limitation of Liability</h2>
    <p>In no event shall the website operator, its authors, contributors, or affiliates be liable for any direct, indirect, special, incidental, or consequential damages arising out of the use or inability to use this Site, even if advised of the possibility of such damages.</p>
    <h2>Consult an Attorney</h2>
    <p>If you require legal advice or have specific legal questions, it is essential to consult with a qualified attorney who can provide guidance tailored to your individual circumstances. You should not delay seeking legal advice or disregard professional legal services based on the information provided on this Site.</p>
    <h2>Minors</h2>
	    <p>This Site is not for use by minors. If you are under 18 years old, you may only use this site with the involvement of a parent, guardian, or other supervising adult.</p>
    <h2>Changes to Disclaimer</h2>
    <p>This disclaimer may be updated or modified from time to time, and it is your responsibility to review it periodically to stay informed of any changes.</p>

    <h3>By using this Site, you agree to the terms of this disclaimer. If you do not agree with these terms, please refrain from using this website.</h3>

            </div>
        </div>
    );
}

export default Disclaimer;
