import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';



import './SoloDisputeVerdictDisplay.css';
const SERVER_IP = process.env.REACT_APP_SERVER_IP;

// Assume your logo is in the public folder and is named verdikta-logo.png
const logoPath = '/background4.ico'; // or the URL if hosted on a CDN

const initialFormData = () => ({
  dispute_ref_id: uuidv4(),
  contract_ref_id: "",
  dispute_title: "",
  dispute_description: "",
  defendant_rebuttal: "",
});

const SoloDisputeVerdictDisplay = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData); // Initialize form data with the initial structure
  const [loading, setLoading] = useState(false);
  const [dispute, setDispute] = useState(null);
  const { dispute_ref_id } = useParams(); // Extracting from the route
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const shareUrl = window.location.href; // This will hold the URL of the current page for sharing
  const feedbackFormRef = useRef(null);


  const toggleFeedbackForm = () => {
    setShowFeedbackForm(!showFeedbackForm);
    
  };
  
  const handleFeedbackSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const feedback = event.target.feedback.value;
    const newDisputeRefId = uuidv4(); // Generate a new UUID for the new dispute


    // augment the reubuttal with the feedback
    const augmentedRebuttal = dispute.defendant_rebuttal + "\n\n" + 
    "Additional rebuttal information for consideration:"  + feedback;

  // Prepare disputeData and answers
  const disputeData = {
    dispute_ref_id: newDisputeRefId, // Use the newly generated dispute_ref_id
    contract_ref_id: dispute.contract_ref_id, // Assuming this is retained from the original dispute
    dispute_title: dispute.dispute_title,
    dispute_description: dispute.dispute_description,
    defendant_rebuttal: augmentedRebuttal,
    status: "PENDING",
    verdict_ref_id: "-1", // Assuming a new verdict will be created
    dispute_tone: dispute.dispute_tone // Assuming you're retaining the tone from the original dispute
  };

  const plaintiffQAs = dispute.plaintiffQA.questions.map((question, index) => {
    return {
      question: question,
      answer: dispute.plaintiffQA.answers[index] || " " // Make sure this is not null or undefined
    };
  });

  const defendantQAs = dispute.defendantQA.questions.map((question, index) => {
    return {
      question: question,
      answer: dispute.defendantQA.answers[index] || " " // Make sure this is not null or undefined
    };
  });
  
  const answerData = {
    plaintiff: plaintiffQAs,
    defendant: defendantQAs
  };

  // Send POST request
  try {
      const verdictResponse = await axios.post(`${SERVER_IP}/api/instant_verdict`, { 
        disputeData, 
        answers: answerData, 
        label: dispute.dispute_tone 
      });

      if (verdictResponse.data) {
          navigate(`/verdict-display/${disputeData.dispute_ref_id}`);
      }
      setLoading(false);
      } catch (error) {
        console.error('Failed to get verdict:', error);
      }
  };
  useEffect(() => {
    if (dispute_ref_id) {
        fetch(`${SERVER_IP}/api/disputes/${dispute_ref_id}`)
            .then(response => response.json())
            .then(data => {
                if (data.status === "RESOLVED") {
                    setDispute(data);
                } else {
                    // Handle scenarios when the dispute status isn't "RESOLVED"
                    console.error("Dispute is not resolved.");
                }
            })
            .catch(error => {
                console.error('Error fetching dispute:', error);
            });
    }
}, [dispute_ref_id]);

useEffect(() => {
  if (showFeedbackForm && feedbackFormRef.current) {
    feedbackFormRef.current.scrollIntoView({ behavior: 'smooth' });
  }
}, [showFeedbackForm]);


if (!dispute) {
  return <div>Loading...</div>;
};
  
  return (
    <div className="solo-dispute-verdict-display">
      <div className="verdikta-logo-container">
        <Link to="/">
        <img src={logoPath} alt="Verdikta Logo" className="verdikta-logo"/>
        <div className="logo-overlay-text"><strong>Verdikta has rendered a verdict</strong></div>
        </Link>
      </div>
      <h1 className="dispute-title">Dispute Title: {dispute.dispute_title || "No title"}</h1>
      <p className="dispute-id">Dispute ID: {dispute.dispute_ref_id || "Instant Dispute"}</p>
      <p><strong>Description:</strong> {dispute.dispute_description || "No description"}</p>
      <p><strong>Contrary perspective on the dispute:</strong> {dispute.defendant_rebuttal || "No rebuttal"}</p>
      <h2>VERDICT</h2>
      <p className="verdict-text">{dispute.dispute_verdict || "No verdict"}</p>
      <div className="share-buttons">
        <CopyToClipboard text={shareUrl} onCopy={() => alert('Link copied to clipboard!')}>
          <button>Copy Link</button>
        </CopyToClipboard>
        <button
          onClick={() => window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent("Check out this verdict from Verdikta!")}`, "_blank")}
          className="share-button"
        >
          <FontAwesomeIcon icon={faTwitter} />  Share on Twitter
        </button>
        <button onClick={toggleFeedbackForm} className="feedback-button">
        <FontAwesomeIcon icon={faArrowDown} /> Disagree? Add your feedback!
        </button>
      </div>
      <h3>Supporting Material:</h3>
      <h4>Plaintiff Questions and Answers:</h4>
      <ul>
        {Array.isArray(dispute.plaintiffQA?.questions) && Array.isArray(dispute.plaintiffQA?.answers) ? (
          dispute.plaintiffQA.questions.map((question, index) => (
            <li key={index}>
              <p><strong>Question:</strong> {question}</p>
              <p><strong>Answer:</strong> {dispute.plaintiffQA.answers[index]}</p>
            </li>
          ))
        ) : (
          <p>No questions and answers for the plaintiff.</p>
        )}
      </ul>
      <h4>Defendant Questions and Answers:</h4>
      <ul>
        {Array.isArray(dispute.defendantQA?.questions) && Array.isArray(dispute.defendantQA?.answers) ? (
          dispute.defendantQA.questions.map((question, index) => (
            <li key={index}>
              <p><strong>Question:</strong> {question}</p>
              <p><strong>Answer:</strong> {dispute.defendantQA.answers[index]}</p>
            </li>
          ))
        ) : (
          <p>No questions and answers for the defendant.</p>
        )}
      </ul>
      <ul>
        {showFeedbackForm && (
          <div className="feedback-form-container" ref={feedbackFormRef}>
            <h3>Add Your Feedback</h3>
            <form onSubmit={handleFeedbackSubmit}>
              <textarea
                name="feedback"
                placeholder="Share your perspective on the dispute by adding to the contrary perspective..."
                required
              ></textarea>
              <button 
                type="submit" 
                disabled={loading} 
                className={`submit-button ${loading ? "loading-button" : ""}`}
                >
                {loading ? "Deliberating..." : "Submit"}
              </button>
            </form>
          </div>
        )}
      </ul>
    </div>
  );
};

export default SoloDisputeVerdictDisplay;
