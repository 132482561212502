import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import './DisputeEntry.css';

// Define initial form data
const initialFormData = () => ({
  dispute_ref_id: uuidv4(),
  contract_ref_id: "",
  plaintiff_eth_address: "",
  defendant_eth_address: "",
  dispute_title: "",
  dispute_description: "",
  plaintiffQA: [],  
  defendantQA: [],  
});

const DisputeEntry = ({account}) => {
  const [formData, setFormData] = useState(initialFormData); // Initialize form data

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Check that plaintiff and defendant addresses are not the same
    if (formData.plaintiff_eth_address === formData.defendant_eth_address) {
      alert("Plaintiff and defendant addresses cannot be the same.");
      return;
    }
    console.log('Reached handleSubmit');
    console.log(formData);// for debug for now
    const SERVER_IP = process.env.REACT_APP_SERVER_IP;



    // Add status and verdictRefId before sending the request
    const fullFormData = { 
        ...formData, 
        status: "AWAITING_REBUTTAL", 
        verdict_ref_id: "-1", 
        plaintiffQA: formData.plaintiffQA,
        defendantQA: formData.defendantQA,
      };

    try {
      
      const response = await fetch(`${SERVER_IP}/api/disputes`, { // current backend API endpoint (NMT)
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(fullFormData)  // send the full form data
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "An error occurred while creating the dispute");
      }

      // If the server responds with success, include the dispute_ref_id in the alert message
      alert(`A new dispute has been successfully filed. Dispute Ref ID: ${data.dispute_ref_id}`);
      
      // Update formData with the new dispute_ref_id
      setFormData(initialFormData());

    } catch (error) {
      console.log('Error:',error);
      alert("An error occurred while creating the dispute.");
    }
  };

  const autofillAddress = (fieldName) => {
    if (account) { // Account was passed as a prop
        let newFormData = { ...formData };

        if (fieldName === "plaintiff_eth_address") {
            newFormData.plaintiff_eth_address = account;
        } else if (fieldName === "defendant_eth_address") {
            newFormData.defendant_eth_address = account;
        }
        setFormData(newFormData);
    } else {
        alert('Please connect your Ethereum wallet.');
    }
}

useEffect(() => {
  if (
      formData.plaintiff_eth_address !== "" && 
      formData.defendant_eth_address !== "" && 
      formData.plaintiff_eth_address === formData.defendant_eth_address
  ) {
      alert("Plaintiff and defendant addresses cannot be the same.");
      let newFormData = { ...formData };
      if(newFormData.plaintiff_eth_address === newFormData.defendant_eth_address){
          newFormData.defendant_eth_address = "";
      }
      setFormData(newFormData);
  }
}, [formData]);


return (
  <div className="dispute-entry">
    <h1>New Dispute</h1>
    <form onSubmit={handleSubmit}>
          {/* Add read-only field for dispute_ref_id */}
        <label>
          Dispute Ref ID:
          <input
            type="text"
            name="dispute_ref_id"
            value={formData.dispute_ref_id}
            readOnly
          />
        </label>
        <br />
      <label>
        Contract Reference ID:
        <input
          type="text"
          name="contract_ref_id"
          value={formData.contract_ref_id}
          onChange={handleInputChange}
        />
      </label>
      <br />
      <label>
        Plaintiff ETH Address:
        <input
          type="text"
          name="plaintiff_eth_address"
          value={formData.plaintiff_eth_address}
          onChange={handleInputChange}
        />
        <button type="button" onClick={() => autofillAddress("plaintiff_eth_address")}>
          Autofill Address
        </button>
      </label>
      <br />
      <label>
        Defendant ETH Address:
        <input
          type="text"
          name="defendant_eth_address"
          value={formData.defendant_eth_address}
          onChange={handleInputChange}
        />
        <button type="button" onClick={() => autofillAddress("defendant_eth_address")}>
          Autofill Address
        </button>
      </label>
      <br />
             {/* Add field for dispute_title */}
       <label>
          Dispute Title:
          <input
            type="text"
            name="dispute_title"
            value={formData.dispute_title}
            onChange={handleInputChange}
          />
        </label>
        <br />
      <div className="dispute-entry-field"> {/* Add this line */}
        <label>
          Dispute Description:
        </label>
        <textarea
          name="dispute_description"
          value={formData.dispute_description}
          onChange={handleInputChange}
        />
      </div> {/* And this line */}
      <br />
      <input type="submit" value="Submit" />
    </form>
  </div>
);
};


export default DisputeEntry;

