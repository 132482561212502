import React from 'react';
import { Link } from 'react-router-dom';
import './ArbitrationSuccess.css';

const ArbitrationSuccess = () => {
    return (
        <div className="arbitration-success-container">
            <h2>Arbitration questions submitted successfully</h2>
            <div className="links-container">
                <Link to="/" className="success-link">Home</Link>
                <Link to="/my-dashboard" className="success-link">MyDashboard</Link>
            </div>
        </div>
    );
}

export default ArbitrationSuccess;
