import React, { useState } from 'react';
import './HomePage.css';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
    const [issueName, setIssueName] = useState('');
    const navigate = useNavigate();

    const handleSoloDisputeClick = () => {
        navigate('/solo-dispute', { state: { issueName } });
    };

    const handleTwoPartyDisputeClick = () => {
        navigate('/instant-dispute', { state: { issueName } });
    };

    // Assume your logo is in the public folder and is named logo-r2.png
    const logoPath = '/logo-r2.png'; // or the URL if hosted on a CDN

    return (
        <div className="home">
            <div className="home-content">
                <h1>Verdikta</h1>
                <img src={logoPath} alt="Verdikta Logo" className="verdikta-logo"/>
                <h2>Automating Fairness</h2>
                <p className="intro-paragraph">
                    Verdikta manages agreements between parties using AI and blockchain technologies. It provides fast, transparent, corruption-resistant, and cost-effective agreement management, protecting buyers and sellers in a way not possible with traditional institutions.
                </p>
                <p className="separation-paragraph">
                    <strong style={{ color: '#333' }}>In a Dispute?</strong> Verdikta's Instant Verdicts help resolve disputes and solve problems for free and anonymously. To try it, provide a name for your issue and proceed either alone or with another person.
                </p>
                <div className="issue-input-container">
                    <label htmlFor="issueName" className="issue-label">Issue Name:</label>
                    <input type="text"
                           id="issueName"
                           placeholder="Enter a name for the issue here..."
                           className="issue-input"
                           value={issueName}
                           onChange={e => setIssueName(e.target.value)}
                    />
                </div>
                <div className="button-container">
                    <button className="cta-button solo" onClick={handleSoloDisputeClick}>I'm by Myself</button>
                    <button className="cta-button duo" onClick={handleTwoPartyDisputeClick}>There Are Two of Us</button>
                </div>
                <p className="notification-invitation">
                    We're still hard at work building the future of justice. If you would like to be notified as we roll out features, please join our <a href="https://verdikta.wufoo.com/forms/join-verdiktas-mailing-list/" target="_blank" rel="noopener noreferrer">mailing list</a>.
                </p>
            </div>
            <footer className="home-footer">
                <p>&copy; 2024 Verdikta</p>
            </footer>
        </div>
    );    
};

export default HomePage;
