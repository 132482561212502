import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Navbar";
import About from "./About";
import Disclaimer from "./Disclaimer";
import Privacy from "./Privacy";
import Resources from "./Resources";
import HomePage from "./HomePage";
import ContractEntry from "./ContractEntry";
import ContractDetail from "./ContractDetail";
import DisputeEntry from "./DisputeEntry";
import DisputeDetail from "./DisputeDetail";
import DefendantRebuttal from "./DefendantRebuttal";
import ArbitrationSuccess from "./ArbitrationSuccess";
import ArbitrationChat from "./ArbitrationChat";
import Arbitration from "./Arbitration";
import MyDashboard from "./MyDashboard";
import VerdictDisplay from "./VerdictDisplay";
import InstantDispute from "./InstantDispute";
import SoloDispute from "./SoloDispute";
import SoloDisputeVerdictDisplay from "./SoloDisputeVerdictDisplay";
import InstantVerdictDisplay from "./InstantVerdictDisplay";
import Wallet from "./Wallet";
import useMatomoTracking from './useMatomoTracking'; // <-- Import the hook

import './App.css';


function App() {
    const [account, setAccount] = useState(null);
    const [verdict, setVerdict] = useState(null);

    useMatomoTracking();
  
    return (
                <Routes>
                    <Route path="/about" element={
                        <div className="AppAboutTop">
                            <div className="AppAbout">
                                <Navbar account={account}/>
                                <Wallet onWalletConnect={setAccount} /> 
                                <About />
                            </div>
                        </div>
                    } />
                    <Route path="*" element={
                        <div>
                            <div className="App"></div>
                            <Navbar account={account}/>
                            <Wallet onWalletConnect={setAccount} /> 
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                        {/*   <Route path="/about" element={<About />} />  */}
                                <Route path="/disclaimer" element={<Disclaimer />} />  
                                <Route path="/privacy" element={<Privacy />} />  
                                <Route path="/resources" element={<Resources />} />  
                                <Route path="/instant-dispute/:label?" element={<InstantDispute />} />
                                <Route path="/solo-dispute/:label?" element={<SoloDispute />} />
                                <Route path="/verdict-display/:dispute_ref_id" element={<VerdictDisplay />} />
                                <Route path="/instant-verdict-display" element={<InstantVerdictDisplay verdict={verdict} />} />
                                <Route path="/contract-entry" element={<ContractEntry account={account} />} />
                                <Route path="/contracts/:contractId" element={<ContractDetail />} />
                                <Route path="/disputes/:disputeId/rebuttal" element={<DefendantRebuttal />} />
                                <Route path='/arbitration-success' element={<ArbitrationSuccess />} /> 
                                <Route path="/arbitration/:disputeId" element={<Arbitration account={account}/>} />
                                <Route path="/dispute-entry" element={<DisputeEntry account={account} />} />
                                <Route path="/disputes/:disputeId" element={<DisputeDetail />} />
                                <Route path="/arbitration-chat" element={<ArbitrationChat />} />
                                <Route path="/verdict-display" element={<VerdictDisplay />} />
                                <Route path="/solo-dispute-verdict-display/:dispute_ref_id" element={<SoloDisputeVerdictDisplay />} />
                                <Route path="/my-dashboard" element={<MyDashboard account={account} />} />
                            </Routes>
                        </div>
                        } />
                </Routes>
    );
}

export default App;
