import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import './InstantVerdictDisplay.css';




const InstantVerdictDisplay = () => {
  const location = useLocation();
  const disputeID = location.state?.disputeID || "Instant Dispute";
  const verdict = location.state?.verdict || "No verdict";
  const title = location.state?.title || "No title";
  const description = location.state?.description || "No description";
  const rebuttal = location.state?.rebuttal || "No rebuttal";
  const plaintiffQA = location.state?.plaintiffQA || [];
  const defendantQA = location.state?.defendantQA || [];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="instant-verdict-display">
      <h2>Dispute Title: {title}</h2>
      <h1>Dispute ID: {disputeID}</h1>
      <p><strong>Description:</strong> {description}</p>
      <p><strong>Defendant Rebuttal:</strong> {rebuttal}</p>
      <h2>Verdict:</h2>
      <p className="verdict-text">{verdict}</p>
      <h3>Supporting Material:</h3>
      <h4>Plaintiff Questions and Answers:</h4>
      <ul>
        {plaintiffQA.map((qa, index) => (
          <li key={index}>
            <p><strong>Question:</strong> {qa.question}</p>
            <p><strong>Answer:</strong> {qa.answer}</p>
          </li>
        ))}
      </ul>
      <h4>Defendant Questions and Answers:</h4>
      <ul>
        {defendantQA.map((qa, index) => (
          <li key={index}>
            <p><strong>Question:</strong> {qa.question}</p>
            <p><strong>Answer:</strong> {qa.answer}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InstantVerdictDisplay;


