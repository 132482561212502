import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams,useLocation } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import axios from 'axios';
import './SoloDispute.css';

const initialFormData = (disputeTitle = "") => ({
  dispute_ref_id: uuidv4(),
  contract_ref_id: "",
  dispute_title: disputeTitle, // Set the initial dispute title to the issue name
  dispute_description: "",
  defendant_rebuttal: "",
});
const SERVER_IP = process.env.REACT_APP_SERVER_IP;


const SoloDispute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { label } = useParams();

  // Check if issueName is passed via location state
  const issueNameFromHomePage = location.state?.issueName;

  // Use the initialFormData function to set the initial state
  const [formData, setFormData] = useState(initialFormData(decodeURIComponent(label || '').replace(/_/g, ' '))); // If label is present, initialize formData with it

  const [questions, setQuestions] = useState([]); // State to store returned questions
  const [verdict, setVerdict] = useState(null); // State to store verdict
  const [showDefendantRebuttal, setShowDefendantRebuttal] = useState(false); // Added: Control visibility of Defendant Rebuttal
  const [isInitialSubmission, setIsInitialSubmission] = useState(true); // New state to track if the initial submission has been made


  const [loading, setLoading]  = useState(false);

  // Initialize these as empty strings to handle the case when label is null or undefined
  let words = [];
  let capitalizedWordString = "";

  // create word lists from the URL and make a string for insertion in the HTML
  if (label && typeof label === 'string') {
    words = label.split('_');
    const leng = words.length;
    let capitalizedWords = words;

    for (let ii = 0; ii < leng; ii++) {
      capitalizedWords[ii] = capitalizedWords[ii].charAt(0).toUpperCase() + capitalizedWords[ii].slice(1);
    }
    capitalizedWordString = capitalizedWords.join(" ");
  }

  const insertionString="for "+capitalizedWordString;

  const questionsRef = useRef(null);

  // A state to store the answers to the questions
  const [answers, setAnswers] = useState({});

  const handleAnswerChange = (index, answer) => {
    setAnswers(prevAnswers => ({ ...prevAnswers, [index]: answer }));
};

  // scroll to questions when they are returned
  useEffect(() => {
    if (questions.length > 0 && questionsRef.current) {
      questionsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [questions]);

  // When the component mounts or label changes, set the initial form data
  useEffect(() => {
    // If coming from the homepage, the issue name will be in the state
    if (issueNameFromHomePage) {
      setFormData(currentFormData => ({
        ...currentFormData,
        dispute_title: issueNameFromHomePage, // Directly use the issue name from the homepage
      }));
    } else if (label) {
      // If there is a label from the URL, use it as before
      setFormData(currentFormData => ({
        ...currentFormData,
        dispute_title: decodeURIComponent(label.replace(/_/g, ' ')), // Replace underscores with spaces and decode URI component
      }));
    }
  }, [label, issueNameFromHomePage]);
  
  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Check if it's the initial form submission
    if (isInitialSubmission) {
        if (!formData.dispute_description.trim()) {
            // Optionally set an error message state here to inform the user
            setLoading(false);
            return; // Exit the function if dispute_description is empty
        }

        // Show the Defendant Rebuttal section and wait for the user to fill it out
        setShowDefendantRebuttal(true);
        setIsInitialSubmission(false); // Mark initial submission as done
        setLoading(false);
    } else {
        // This block now only runs after the defendant rebuttal has been completed
       
      
        const sanitizedFormData = Object.fromEntries(
          Object.entries(formData).map(([key, value]) => [key, value || " "])
        );

        const fullFormData = { 
            ...sanitizedFormData, 
            status: "PENDING", 
            verdict_ref_id: "-1",
        };
        
        // Prepare the data to send to the server
        const dataToSend = {
            disputeData: fullFormData,
            answers: answers,  // the answers to the questions
            label: capitalizedWordString // this string is used to set the tone of the AI during arbitration. 
        };

        try {
            const response = await fetch(`${SERVER_IP}/api/instant_disputes`, {
              method: "POST",
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(dataToSend)
            });

            const data = await response.json();

            if (!response.ok) {
              throw new Error(data.message || "An error occurred while creating the solo dispute");
            }

            // Store the returned questions in state
            setQuestions(
                data.questions.filter((question) => /^\d/.test(question))
              );
        
          } catch (error) {
            console.error(error);
            alert("An error occurred while creating the solo dispute. Please check the console for more details.");
          }
          setLoading(false);
        }
  };

  const handleSubmitNoQuestions = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    // Check if it's the initial form submission
    if (isInitialSubmission) {
      if (!formData.dispute_description.trim()) {
        // Optionally set an error message state here to inform the user
        setLoading(false);
        return; // Exit the function if dispute_description is empty
      }
  
      // Show the Defendant Rebuttal section and wait for the user to fill it out
      setShowDefendantRebuttal(true);
      setIsInitialSubmission(false); // Mark initial submission as done
      setLoading(false);
    } else {
      // This block now only runs after the defendant rebuttal has been completed
  
      // Prepare empty (because questions were not asked) questions and answers to be sent
      // The code below allows the creation of any number of empty questions, if required.
      const numberOfEntries = 0;
      const plaintiffQAs = Array.from({ length: numberOfEntries }).map(() => ({
        question: " ",
        answer: " "
      }));
      const defendantQAs = Array.from({ length: numberOfEntries }).map(() => ({
        question: " ",
        answer: " "
      }));
  
      // Prepare disputeData and answers
      const disputeData = { ...formData, status: "PENDING", verdict_ref_id: "-1" };
      const answerData = { plaintiff: plaintiffQAs, defendant: defendantQAs };
  
      // Send POST request
      try {
        const verdictResponse = await axios.post(`${SERVER_IP}/api/instant_verdict`, { disputeData, answers: answerData, label: capitalizedWordString });
  
        setVerdict(verdictResponse.data.verdict);
  
        navigate(`/solo-dispute-verdict-display/${formData.dispute_ref_id}`);
  
        // Reset the form and answers
        setFormData(initialFormData());
        setAnswers({});
        setQuestions([]);
      } catch (error) {
        console.error('Failed to get verdict:', error);
        // Optionally, set an error state here to display an error message to the user
      } finally {
        // Ensure loading is always turned off after the operation completes
        setLoading(false);
      }
    }
  };
  
  
  const handleSubmitQuestions = async (event) => {
    event.preventDefault();
    setLoading(true);

    const sanitizedAnswers = Object.fromEntries(
      Object.entries(answers).map(([key, value]) => [key, value || " "])
    );

    // Prepare questions and answers to be sent
    const plaintiffQAs = questions.slice(0,5).map((question, index) => ({
      question: question,
      answer: sanitizedAnswers[index]
    }));
  
    const defendantQAs = questions.slice(5,10).map((question, index) => ({
      question: question,
      answer: sanitizedAnswers[index + 5]
    }));
  
    // Prepare disputeData and answers
    const disputeData = { ...formData, status: "PENDING", verdict_ref_id: "-1" };
    const answerData = { plaintiff: plaintiffQAs, defendant: defendantQAs };
  
    // Send POST request
    try {
	    const verdictResponse = await axios.post(`${SERVER_IP}/api/instant_verdict`, { disputeData, answers: answerData, label: capitalizedWordString });

      setVerdict(verdictResponse.data.verdict);

      navigate(`/verdict-display/${formData.dispute_ref_id}`);


    // Reset the form and answers
    setFormData(initialFormData());
    setAnswers({});
    setQuestions([]);

    setLoading(false);

        } catch (error) {
      console.error('Failed to get verdict:', error);
    }
  };


  return (
    <div className="solo-dispute">
    <h1 className="page-title">
      {label ? `Resolve Your Dispute ${insertionString}` : "Solo Dispute Resolution"}
    </h1>
    <p className="page-subtitle">
      {"Submit your dispute details below and get fair and unbiased advice."}
    </p>
      <form onSubmit={handleSubmit}>
        <label>
          Title:
          <input
            disabled={loading}
            type="text"
            name="dispute_title"
            value={formData.dispute_title}
            onChange={handleInputChange}
            placeholder="Enter a title for the dispute"
            title="Enter a title for the this dialog with the AI"
          />
        </label>
        <br />
        {/* Above Plaintiff Description */}
        <p className="form-guidance">
          Please describe the dispute in detail. Include the background, any critical dates or amounts involved, what outcome you seek, and why you believe your position is justified. Think about who, what, when, where, why, and how.
        </p>
        <label>
          Your Description of the Dispute (Plaintiff Claim):
          <textarea
            disabled={loading}
            name="dispute_description"
            value={formData.dispute_description}
            onChange={handleInputChange}
            placeholder="Describe the dispute in detail from your perspective and why you think you are correct."
            title="Enter a description of the dispute from your perspective"
          />
        </label>
        <br />
         {/* Conditional rendering for Defendant Rebuttal */}
         {showDefendantRebuttal && ( 
          <>
          <p className="form-guidance">
          Now consider the dispute from the opposite perspective. Describe as best you can the argument that the other party would make as to why they are right and you are wrong.  This should include any counterpoints, factual discrepancies, or additional context that challenges your claim.  Providing this information is important for Verdikta to provide a fair and balanced judgement.  It will also help you strengthen your argument.
          </p>
        <label> 
          Counterargument (Defendant Rebuttal):
          <textarea
            disabled={loading}
            name="defendant_rebuttal"
            value={formData.defendant_rebuttal}
            onChange={handleInputChange}
            placeholder="Provide your counterargument or rebuttal to the dispute assuming you are the defendant.  Do your best to argue the opposite perspective, as this will help Verdikta deliver a fair judgement."
            title="Enter a rebuttal from the opposite perspective (of a person, if applicable, or philosophy)"
          />
        </label>
        </>
         )}
        <br />
      {/* use two side-by-side buttons, 1st for no questions, 2nd for questions (conditionally rendered) */}
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <button
          type="button"  // Change to "button" to prevent form submission on click
          disabled={loading || !formData.dispute_description.trim() || (showDefendantRebuttal &&!formData.defendant_rebuttal.trim() ) }
          className={`submit-button ${loading ? "loading-button" : ""}`}
          style={{ flex: 1, marginRight: '20px' }}
          onClick={handleSubmitNoQuestions} // Ensure correct function is called here
        >
          {loading ? "Deliberating..." : "Submit"}
        </button>

        {showDefendantRebuttal && (
          <button
            type="submit"
            disabled={loading || !formData.defendant_rebuttal.trim()} // Ensure button is enabled based on defendant_rebuttal
            className={`submit-nq-button ${loading ? "loading-button" : ""}`}
            style={{ width: '30%' }}
          >
            {loading ? "Deliberating..." : "Seek Questions"}
          </button>
        )}
      </div>
     </form>
      {/* Generate form with questions if they exist */}
      {questions.length > 0 && (
  <div ref={questionsRef}>
    <div className="questions-container">
    <form onSubmit={handleSubmitQuestions}>
      <h2>Questions for Your Perspective:</h2>
    {questions.slice(0,5).map((question, index) => (
    <div key={index} className="question-input-pair">
        <div className="question-text">
            <label htmlFor={`plaintiff-question-${index}`}>{question}</label>
        </div>
        <div className="input-field">
            <input 
            disabled={loading}
            type="text" 
            id={`plaintiff-question-${index}`} 
            name={`plaintiff-question-${index}`}
            onChange={(e) => handleAnswerChange(index, e.target.value)}
            />
        </div>
    </div>
    ))}
    <h2>Questions for the Opposite Perspective:</h2>
    {questions.slice(5,10).map((question, index) => (
    <div key={index} className="question-input-pair">
        <div className="question-text">
            <label htmlFor={`defendant-question-${index}`}>{question}</label>
        </div>
        <div className="input-field">
            <input 
            disabled={loading}
            type="text" 
            id={`defendant-question-${index}`}
            name={`defendant-question-${index}`}    
            onChange={(e) => handleAnswerChange(index+5,e.target.value)} 
            className="input-fixed-width"
            />
        </div>
    </div>
    ))}
    <button 
      type="submit" 
      disabled={loading} 
      className={`submit-button ${loading ? "loading-button" : ""}`}
    >
    {loading ? "Deliberating..." : "Submit"}
    </button>
    </form>
    </div>
  </div>
)}
    </div>
  );  
};
  
export default SoloDispute;
