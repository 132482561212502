import { useLocation, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './DisputeDetail.css';

const DisputeDetail = () => {
  const location = useLocation();
  const { disputeId } = useParams();
  const [dispute, setDispute] = useState({});
  const SERVER_IP = process.env.REACT_APP_SERVER_IP;


  useEffect(() => {
    if(location.state){
      setDispute(location.state.dispute);
    }
    else {
      fetch(`${SERVER_IP}/api/disputes/${disputeId}`)
      .then(response => {
        if (!response.ok) throw new Error('Failed to fetch dispute');
        return response.json();
      })
      .then(data => {
        setDispute(data);
        })
      .catch(error => console.error('Failed to fetch dispute:', error));
    }
  }, [location.state, disputeId]);

  return (
    <div className="dispute-detail">
      { dispute && 
        <>
          <h1>{dispute.dispute_title}</h1>
          <h2>Dispute ID: {dispute.dispute_ref_id}</h2>
          <h3>Contract Reference ID: {dispute.contract_ref_id}</h3>
          <p>Plaintiff ETH Address: {dispute.plaintiff_eth_address}</p>
          <p>Defendant ETH Address: {dispute.defendant_eth_address}</p>
          <p> Description:</p> 
          <p>{dispute.dispute_description}</p>
          <p> Rebuttal:</p>
          <p>{dispute.defendant_rebuttal}</p>
          <p>Status: {dispute.status}</p>
          <p>Verdict Reference ID: {dispute.verdict_ref_id}</p>
        </>
      }
    </div>
  );
};

export default DisputeDetail;
