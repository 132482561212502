import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './Arbitration.css';

function Arbitration({account}) {
  const { disputeId } = useParams();

  const [dispute, setDispute] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const SERVER_IP = process.env.REACT_APP_SERVER_IP;



  useEffect(() => {
    // Fetch dispute data
    fetch(`${SERVER_IP}/api/disputes/${disputeId}`)
      .then(response => response.json())
      .then(data => {
        console.log(data); // Log the response data
        setDispute(data);
      });
    
  }, [disputeId]);


  useEffect(() => {
    if (dispute) {
        // Initialize answers with empty strings
        const questionsLength = (dispute.plaintiffQA?.questions || dispute.defendantQA?.questions)?.length || 0;
        const initialAnswers = new Array(questionsLength).fill('');
    
        setAnswers(initialAnswers);
    }
  }, [dispute]);



  const handleAnswerChange = (index, newAnswer) => {
    // Update specific answer
    setAnswers(prevAnswers => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[index] = newAnswer;
      return updatedAnswers;
    });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

  // Set loading to true when submit starts
  setLoading(true);

  // Construct the payload with account and answers
  const payload = {
    account: account,
    answers: answers
  };

  // Update the dispute with the payload
  fetch(`${SERVER_IP}/api/disputes/${disputeId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload)
  })
  .then(response => response.json())
  .then(data => {
    // Check if the response includes an error message
    if (data.message) {
      // Handle the error message accordingly
      console.error('Error:', data.message);
      // You might also update the UI to show the error to the user
    } else {
      // Handle successful submission
      navigate('/arbitration-success');
    }
  })
  .catch(error => {
    console.error('Network error:', error);
    // Handle network errors
  })
  .finally(() => {
    // Set loading to false when submit ends, regardless of the outcome
    setLoading(false);
  });
}

  if (!dispute) {
    return <div>Loading...</div>;
  }

    const questionsObject = account === dispute.defendant_eth_address ? dispute.defendantQA : dispute.plaintiffQA;
    if (!questionsObject || !questionsObject.questions || questionsObject.questions.length === 0) {
        return <div>No questions found.</div>;
      }
      
    const questions = questionsObject.questions;
    if (!questions || questions.length === 0) {
    return <div>No questions found.</div>;
  }

  return (
    <div className="arbitration-form-container">
    <h2>Title: {dispute.dispute_title}</h2>
    <small style={{ display: 'block', marginBottom: '10px', color: 'grey' }}>
        Dispute Reference ID: {disputeId}
    </small>
    <p style={{ textAlign: 'justify', fontWeight: 'bold' }}>
        Questions for the {account === dispute.defendant_eth_address ? "Defendant" : "Plaintiff"}:
    </p>
    <form onSubmit={handleSubmit}>
        {questions.map((question, index) => (
        <div key={index} className="arbitration-form-question" >
            <label>{question}</label>
            <textarea 
            value={answers[index]} 
            onChange={e => handleAnswerChange(index, e.target.value)}
            />
        </div>
        ))}
        <button 
          type="submit" 
          disabled={loading} 
          className={loading ? "loading-button" : ""}
        >
          {loading ? 'Deliberating' : 'Submit'}
        </button>
    </form>
    </div>
  );
};

export default Arbitration;

