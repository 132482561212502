import React from 'react';
import './Privacy.css';

const Privacy = () => {
    return (
        <div className="privacy-container">
            <div className="content-rectangle">

<h2>Privacy Policy</h2>


        <p>This privacy policy ("policy") will help you understand how the operators of this website  ("us", "we", "our") use and protect the data you provide to us when you visit and use verdikta.com ("website", "service").</p>

        <p>We reserve the right to change this policy at any given time, of which you will be promptly updated. If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page.</p>

        <h2>What User Data We Collect</h2>

        <p>When you visit the website, we may collect the following data:</p>
        <ol className="no-style-list">
            <li>Your IP address.</li>
            <li>Data profile regarding your online behavior on our website.</li>
            <li>Your Ethereum address used as an ID.</li>
            <li>Any information you explicitly provide to us.</li>
        </ol>

        <h2>Why We Collect Your Data</h2>

        <p>We are collecting your data for several reasons:</p>
        <ol className="no-style-list">
            <li>To implement our service of dispute resolution.</li>
            <li>To better understand your needs.</li>
            <li>To improve our services and products.</li>
            <li>To customize your experience according to your personal preferences.</li>
        </ol>

        <h2>Safeguarding and Securing the Data</h2>

        <p>The operators of this website are committed to securing your data and keeping it confidential. We have taken steps to prevent data theft, unauthorized access, and disclosure by implementing technologies and software that help us safeguard all the information we collect online.</p>

        <h2>Our Cookie Policy</h2>

        <p>Once you agree to allow our website to use cookies, you also agree to use the data it collects regarding your online behavior.</p>

        <p>The data we collect by using cookies is used to customize our website to your needs and in aggregate with others to improve the website. After we use this data for aggregate analysis, the data is removed from our systems.</p>

        <p>Please note that cookies don't allow us to gain control of your computer. They are used strictly for monitoring which pages you find useful and which you do not so that we can provide a better experience for you.</p>

        <p>If you want to disable cookies, you can do it by accessing the settings of your internet browser.</p>

        <h2>Restricting the Collection of your Personal Data</h2>

        <p>At some point, you might wish to restrict the use and collection of your personal data. You can achieve this by doing the following:</p>
        <ol className="no-style-list">
            <li>Do not provide personally identifying information in boxes or forms on the website.</li>
            <li>If you have already shared your information with us, please contact us using the contact-us form on the website, and we will remove the personally identifying information.</li>
        </ol>

        <p>The operators of this website will not lease, sell or distribute personal identifying information to any third parties, unless we have your permission. We might do so if the law forces us.</p>
            </div>
        </div>
    );
}

export default Privacy;
