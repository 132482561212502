import React, { useState, useRef, useEffect } from "react";
import { useNavigate,useParams, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';



import axios from 'axios';

import './InstantDispute.css';

const initialFormData = () => ({
  dispute_ref_id: uuidv4(),
  contract_ref_id: "",
  dispute_title: "",
  dispute_description: "",
  defendant_rebuttal: "",
});
const SERVER_IP = process.env.REACT_APP_SERVER_IP;


const InstantDispute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { label } = useParams();

  // Check if issueName is passed via location state
  const issueNameFromHomePage = location.state?.issueName;

  const [formData, setFormData] = useState(initialFormData); // Initialize form data
  const [questions, setQuestions] = useState([]); // State to store returned questions
  const [verdict, setVerdict] = useState(null); // State to store verdict
  const [loading, setLoading] = useState(false);


  // Initialize these as empty strings to handle the case when label is null or undefined
  let words = [];
  let capitalizedWordString = "";

  // create word lists from the URL and make a string for insertion in the HTML
  if (label && typeof label === 'string') {
    words = label.split('_');
    const leng = words.length;
    let capitalizedWords = words;

    for (let ii = 0; ii < leng; ii++) {
      capitalizedWords[ii] = capitalizedWords[ii].charAt(0).toUpperCase() + capitalizedWords[ii].slice(1);
    }
    capitalizedWordString = capitalizedWords.join(" ");
  }

  const insertionString="for "+capitalizedWordString;

  const questionsRef = useRef(null);

  // A state to store the answers to the questions
  const [answers, setAnswers] = useState({});

  const handleAnswerChange = (index, answer) => {
    setAnswers(prevAnswers => ({ ...prevAnswers, [index]: answer }));
};

  // scroll to questions when they are returned
  useEffect(() => {
    if (questions.length > 0 && questionsRef.current) {
      questionsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [questions]);

    // When the component mounts or label changes, set the initial form data
    useEffect(() => {
      // If coming from the homepage, the issue name will be in the state
      if (issueNameFromHomePage) {
        setFormData(currentFormData => ({
          ...currentFormData,
          dispute_title: issueNameFromHomePage, // Directly use the issue name from the homepage
        }));
      } else if (label) {
        // If there is a label from the URL, use it as before
        setFormData(currentFormData => ({
          ...currentFormData,
          dispute_title: decodeURIComponent(label.replace(/_/g, ' ')), // Replace underscores with spaces and decode URI component
        }));
      }
    }, [label, issueNameFromHomePage]);
    
  
  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const sanitizedFormData = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [key, value || " "])
    );

    const fullFormData = { 
        ...sanitizedFormData, 
        status: "PENDING", 
        verdict_ref_id: "-1",
    };
    
    // Prepare the data to send to the server
    const dataToSend = {
        disputeData: fullFormData,
        answers: answers,  // the answers to the questions
        label: capitalizedWordString // this string is used to set the tone of the AI during arbitration. 
    };

    try {
        const response = await fetch(`${SERVER_IP}/api/instant_disputes`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(dataToSend)
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || "An error occurred while creating the instant dispute");
        }

        // Store the returned questions in state
        setQuestions(
            data.questions.filter((question) => /^\d/.test(question))
          );
    
      } catch (error) {
        console.error(error);
        alert("An error occurred while creating the instant dispute. Please check the console for more details.");
      }
      setLoading(false);
  };


  const handleSubmitNoQuestions = async (event) => {
    event.preventDefault();
    if (!formData.dispute_description.trim()) {
      // Optionally set an error message state here to inform the user
      return; // Exit the function if dispute_description is empty
    }
    setLoading(true);
    
    // Prepare empty (because questions were not asked) questions and answers to be sent
    // The code below allows the creation of any number of empty questions, if required.
    const numberOfEntries=0;
    const plaintiffQAs = Array.from({ length: numberOfEntries }).map(() => ({
    question: " ",
    answer: " "
    }));
    const defendantQAs = Array.from({ length: numberOfEntries }).map(() => ({
    question: " ",
    answer: " "
    }));
  
    // Prepare disputeData and answers
    const disputeData = { ...formData, status: "PENDING", verdict_ref_id: "-1" };
    const answerData = { plaintiff: plaintiffQAs, defendant: defendantQAs };

    // Send POST request
    try {
	    const verdictResponse = await axios.post(`${SERVER_IP}/api/instant_verdict`, { disputeData, answers: answerData, label: capitalizedWordString });

      setVerdict(verdictResponse.data.verdict);

      navigate(`/verdict-display/${formData.dispute_ref_id}`);


    // Reset the form and answers
    setFormData(initialFormData());
    setAnswers({});
    setQuestions([]);

    setLoading(false);

        } catch (error) {
      console.error('Failed to get verdict:', error);
    }
  };
  

  const handleSubmitQuestions = async (event) => {
    event.preventDefault();
    if (!formData.dispute_description.trim()) {
      // Optionally set an error message state here to inform the user
      return; // Exit the function if dispute_description is empty
    }
    setLoading(true);

    const sanitizedAnswers = Object.fromEntries(
      Object.entries(answers).map(([key, value]) => [key, value || " "])
    );

    // Prepare questions and answers to be sent
    const plaintiffQAs = questions.slice(0,5).map((question, index) => ({
      question: question,
      answer: sanitizedAnswers[index]
    }));
  
    const defendantQAs = questions.slice(5,10).map((question, index) => ({
      question: question,
      answer: sanitizedAnswers[index + 5]
    }));
  
    // Prepare disputeData and answers
    const disputeData = { ...formData, status: "PENDING", verdict_ref_id: "-1" };
    const answerData = { plaintiff: plaintiffQAs, defendant: defendantQAs };
  
    // Send POST request
    try {
	    const verdictResponse = await axios.post(`${SERVER_IP}/api/instant_verdict`, { disputeData, answers: answerData, label: capitalizedWordString });

      setVerdict(verdictResponse.data.verdict);

      navigate(`/verdict-display/${formData.dispute_ref_id}`);


    // Reset the form and answers
    setFormData(initialFormData());
    setAnswers({});
    setQuestions([]);

    setLoading(false);

        } catch (error) {
      console.error('Failed to get verdict:', error);
    }
  };


  return (
    <div className="instant-dispute">
      <h1 className="page-title">
      {label ? `Resolve Your Dispute ${insertionString}` : "Instant Dispute Resolution"}
      </h1>
      <p className="page-subtitle">
        {"Both parties can submit dispute details below and get a fair and unbiased advice."}
      </p>
      <form onSubmit={handleSubmit}>
        <label>
          Dispute Title:
          <input
            type="text"
            name="dispute_title"
            value={formData.dispute_title}
            onChange={handleInputChange}
            title="Enter a title for the dispute"
          />
        </label>
        <br />
        <label>
          Dispute Description:
          <textarea
            name="dispute_description"
            value={formData.dispute_description}
            onChange={handleInputChange}
            title="Enter a description of the dispute from the plaintiff's perspective"
            placeholder="Describe the dispute in detail from your perspective and why you think you are correct."

          />
        </label>
        <br />
        <label>
          Rebuttal:
          <textarea
            name="defendant_rebuttal"
            value={formData.defendant_rebuttal}
            onChange={handleInputChange}
            title="Enter a rebuttal from the defendant's perspective"
            placeholder="Have the counterparty write a rebuttal to your dispute from their perspective."

          />
        </label>
        <br />
     {/* use two side-by-side buttons, 1st for questions, 2nd for no questions */}
     <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
     <button
       type="submit"
       disabled={loading || !formData.dispute_description.trim()}
       className={`submit-button ${loading ? "loading-button" : ""}`}
       style={{ flex: 1,  marginRight: '20px' }}
     >
       {loading ? "Deliberating..." : "Next Step: Questions"}
     </button>

     <button
       type="submit"
       disabled={loading || !formData.dispute_description.trim()}
       className={`submit-nq-button ${loading ? "loading-button" : ""}`}
       style={{ width: '30%' }}
       onClick={(event)=>{handleSubmitNoQuestions(event);}} 
     >
       {loading ? "Deliberating..." : "Submit Without Questions"}
     </button>
     </div>

     </form>
      {/* Generate form with questions if they exist */}
      {questions.length > 0 && (
  <div ref={questionsRef}>
    <div className="questions-container">
    <form onSubmit={handleSubmitQuestions}>
      <h2>Questions for the Plaintiff:</h2>
    {questions.slice(0,5).map((question, index) => (
    <div key={index} className="question-input-pair">
        <div className="question-text">
            <label htmlFor={`plaintiff-question-${index}`}>{question}</label>
        </div>
        <div className="input-field">
            <input 
            type="text" 
            id={`plaintiff-question-${index}`} 
            name={`plaintiff-question-${index}`}
            onChange={(e) => handleAnswerChange(index, e.target.value)}
            />
        </div>
    </div>
    ))}
    <h2>Questions for the Defendant:</h2>
    {questions.slice(5,10).map((question, index) => (
    <div key={index} className="question-input-pair">
        <div className="question-text">
            <label htmlFor={`defendant-question-${index}`}>{question}</label>
        </div>
        <div className="input-field">
            <input 
            type="text" 
            id={`defendant-question-${index}`}
            name={`defendant-question-${index}`}    
            onChange={(e) => handleAnswerChange(index+5,e.target.value)} 
            className="input-fixed-width"
            />
        </div>
    </div>
    ))}
    <button 
      type="submit" 
      disabled={loading} 
      className={`submit-button ${loading ? "loading-button" : ""}`}
    >
    {loading ? "Deliberating..." : "Submit"}
    </button>
    </form>
    </div>
  </div>
)}
    </div>
  );  
};
  
export default InstantDispute;
