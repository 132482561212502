import React from 'react';
import './About.css';

const About = () => {
    return (
        <div className="about-container">
            <div className="content-rectangle">
                <h2>About Verdikta</h2>
                <p>Verdikta is a dispute resolution platform that leverages the power of artificial intelligence to help people work out problems.</p>
            </div>
            <div className="content-rectangle">
                <h3>Vision</h3>
                <p>Verdikta transforms the traditional dispute resolution process by making it faster, more efficient, and more accessible. We believe that artificial intelligence, combined with blockchain technology, will democratize arbitration, making it fair and transparent.</p>
            </div>
            <div className="content-rectangle">
                <h3>What We Do</h3>
                <p>We handle a wide range of disputes, from contractual issues to personal disagreements. Our advanced AI system reviews each case individually, questioning and considering all the facts and circumstances before providing a neutral and unbiased decision. The verdict can be used to trigger blockchain actions, using automatic outcomes to give thorough resolution.</p>
            </div>
            <div className="content-rectangle">
                <h3>Get in Touch</h3>
                <p>For more information about our services, or if you have any questions or comments, please <a href="https://verdikta.wufoo.com/forms/verdikta-contact-form/" target="_blank" rel="noopener noreferrer">contact us</a>. We're always here to help.</p>
            </div>
        </div>
    );
}

export default About;
