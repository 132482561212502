import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useMatomoTracking = () => {
  const location = useLocation();

  useEffect(() => {
    console.log("Tracking path:", location.pathname + location.search);
    console.log("Matomo _paq:", window._paq);
    const path = location.pathname + location.search;
    window._paq.push(['setCustomUrl', path]);
    window._paq.push(['trackPageView']);
  }, [location]);
};

export default useMatomoTracking;
