import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';



const Navbar = ({ account, onWalletConnect }) => {  // <-- Here, account is the Ethereum address. Pass it as a prop.
    return (
        <div className="navbar">
        <div className="navbar-items">
            <NavLink to="/" className="item" activeClassName="active" exact>
            Home
            </NavLink>
            <NavLink to="/about" className="item" activeClassName="active">
            About
            </NavLink>
            <div className="dropdown">
                <button className="dropbtn">Instant Verdict</button>
                <div className="dropdown-content">
                    <NavLink to="/solo-dispute" className="item" activeClassName="active" title="Alone?  Submit your dispute and a counterargument to your position.">
                        Submit Solo Dispute
                    </NavLink>
                    <NavLink to="/instant-dispute" className="item" activeClassName="active" title="With the person you're in a dispute with?  Both parties can present their case.">
                        Submit Two-Party Dispute
                    </NavLink>
                </div>
            </div>
            <NavLink 
                    to="/contract-entry" 
                    className={`item ${!account ? 'disabled-link' : ''}`} 
                    activeClassName="active">
                    New Contract
            </NavLink>
            <NavLink 
                    to="/dispute-entry" 
                    className={`item ${!account ? 'disabled-link' : ''}`} 
                    activeClassName="active">
                    New Dispute
            </NavLink>
            <NavLink 
                    to="/my-dashboard" 
                    className={`item ${!account ? 'disabled-link' : ''}`} 
                    activeClassName="active">
                    My Dashboard
            </NavLink>
            <NavLink to="/disclaimer" className="item" activeClassName="active">
            Disclaimer
            </NavLink>
            <NavLink to="/privacy" className="item" activeClassName="active">
            Privacy
            </NavLink>
            <NavLink to="/resources" className="item" activeClassName="active">
            Resources
            </NavLink>
        </div>
        <div className="connect-wallet-wrapper">
        </div>
        </div>
    );
};

export default Navbar;
