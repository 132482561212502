import React from 'react';
import './Resources.css';

const Resources = () => {
    return (
        <div className="resources-container">
            <div className="content-rectangle">

                <h2>Guides</h2>

                <ol className="no-style-list">
                    <li>Verdikta Quickstart Guide: <a href="https://gateway.pinata.cloud/ipfs/Qmddb1hGqdrNJxMfZdnxWUhnYGVVcyn9KfzDKscASTRNvx">https://gateway.pinata.cloud/ipfs/Qmddb1hGqdrNJxMfZdnxWUhnYGVVcyn9KfzDKscASTRNvx</a> </li>
                </ol>
            </div>
            <div className="content-rectangle">
            <h2>Video Tutorials</h2>

            <ol className="no-style-list">
                <li>Verdikta How-to: <a href="https://youtu.be/kLNWS4wVdcA" target="_blank">Instant Disputes</a></li>

            </ol>
            </div>
            <div className="content-rectangle">
            <h2>Verdikta Blog</h2>

            <ol className="no-style-list">
                <li><a href="http://blog.verdikta.com" target="_blank">The Digital Diplomat</a></li>
            </ol>
            </div>
        </div>
    );

}

export default Resources;
